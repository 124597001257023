// import Debounce from './debounce';
// import { loadDynamicScript } from './loadDynamicAssets';
import { hasLoggedIn } from './hasLoggedIn';

// const setCookie = (name, value, days) => {
//   const date = new Date();
//   date.setTime(date.getTime() + (days*24*60*60*1000));
//   const expires = '; expires=' + date.toGMTString();
//   document.cookie = name + '=' + value + expires + ';path=/;domain=paperlesspipeline.com';
// }

// const getParam = p => {
//   const match = RegExp('[?&]' + p + '=([^&]*)').exec(window.location.search);
//   return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
// };

const Google = {
  ts: null,
  didInitGtm: false,
  // storeGoogleGclidInCookie: function() {
  //   if (!this.didInitGtm) {
  //     return;
  //   }
  //   // code taken from: https://support.google.com/google-ads/answer/7012522
  //   const gclid = getParam('gclid');
  //   if (gclid) {
  //     const gclsrc = getParam('gclsrc'); // gclsrc is added by Search Ads 360 - do not want
  //     if (!gclsrc || gclsrc.indexOf('aw') !== -1) {
  //       setCookie('gclid', gclid, 90);
  //     }
  //   }
  // },
  setTimestamp: function(ts) {
    this.ts = ts;
  },
  trackEventGTM: function(event, params) {
    if (
      typeof window === 'undefined' || // need check for gatsby build
      hasLoggedIn() ||
      ( this && !this.didInitGtm )
    ) {
      return;
    }
    // const eventData = {
    //   'event': event,
    //   ...params
    //  };
    // console.log(eventData);
    window.dataLayer?.push({
      'event': event,
      ...params
     });
  },
  pageview: function() {
    if (
      typeof window === 'undefined' || // need check for gatsby build
      hasLoggedIn() ||
      ( this && !this.didInitGtm )
    ) {
      return;
    }
    const { pathname, href, hash } = window.location;
    window.dataLayer?.push({
      'event': 'page_view',
      'pathname': pathname,
      'href': href,
      'hash': hash
     });
  },
  consentDenied: function() {
    if (
      typeof window === 'undefined' || // need check for gatsby build
      hasLoggedIn() ||
      ( this && !this.didInitGtm )
    ) {
      return;
    }
    window.dataLayer?.push({
      'event': 'consent_denied',
    });
  },
  consentGranted: function() {
    if (
      typeof window === 'undefined' || // need check for gatsby build
      hasLoggedIn() ||
      ( this && !this.didInitGtm )
    ) {
      return;
    }
    const gtag = function() {
      window.dataLayer.push(arguments);
    };
    gtag('consent', 'update', {
      'ad_storage': 'granted',
      'ad_user_data': 'granted',
      'ad_personalization': 'granted',
      'analytics_storage': 'granted',
      'functionality_storage': 'granted',
      'personalization_storage': 'granted',
      'security_storage': 'granted'
    });
    window.dataLayer?.push({
      'event': 'consent_granted',
    });
    // capture initial page load - don't need this, page_view is triggered when GTM snippet is loaded
    // this.pageview();
  },
  initGoogleTagManager: function() {
    // example: https://developers.google.com/tag-platform/security/guides/consent?consentmode=advanced#gtag.js_1
    if (
      typeof window === 'undefined' || // need check for gatsby build
      hasLoggedIn() ||
      ( this && this.didInitGtm )
    ) {
      return;
    }
    this.didInitGtm = true;

    // https://developers.google.com/tag-platform/tag-manager/datalayer
    window.dataLayer = window.dataLayer || [];

    const gtag = function(){
      window.dataLayer.push(arguments);
    };

    gtag('consent', 'default', {
      'ad_storage': 'denied',
      'ad_user_data': 'denied',
      'ad_personalization': 'denied',
      'analytics_storage': 'denied',
      'functionality_storage': 'denied',
      'personalization_storage': 'denied',
      'security_storage': 'denied',
      'wait_for_update': 250,
    });

    gtag('set', 'url_passthrough', true);

    const GTM_PROD_ID = 'GTM-TDRS3LT2';
    const GTM_MARKETING_DEV_ID = 'GTM-KK2JSRHL';
    const GTM_ID = process.env.NODE_ENV === 'development' || window.location.hostname.includes('marketing-dev') ? GTM_MARKETING_DEV_ID : GTM_PROD_ID;

    (function(w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push(
        {
          'gtm.start': new Date().getTime(),event:'gtm.js'
        }
      );
      var f = d.getElementsByTagName(s)[0];
      var j = d.createElement(s)
      var dl = l != 'dataLayer' ? '&l='+l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j,f);
    })(window, document, 'script', 'dataLayer', GTM_ID);
  }
};

export default Google;