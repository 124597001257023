import React from 'react';
import PropTypes from 'prop-types';
import { ButtonStyled, InternalLinkButtonStyled, AnchorLinkButtonStyled } from './button.css';

const TYPES = {
  PRIMARY: 'primary',
  OUTLINEPRIMARY: 'outlineprimary',
  SECONDARY: 'secondary',
  OUTLINESECONDARY: 'outlinesecondary',
  TERTIARY: 'tertiary',
  LIGHT: 'light',
  INFO: 'info',
  WARNING: 'warning',
  DANGER: 'danger',
  SUCCESS: 'success',
  CTA_YELLOW: 'cta_yellow',
  CTA_BLUE_GREEN: 'cta_blue_green',
  CTA_BLUE: 'cta_blue',
  COLOR_BTN: 'color_btn',
  COLOR_HOLLOW_BTN: 'color_hollow_btn',
  GRAY: 'gray_hollow'
}
export const SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  EXTRALARGE: 'extralarge',
}
const BaseButton = ({
  text,
  onClick,
  element,
  disabled,
  ripple,
  buttonType,
  buttonSize,
  internalLink,
  anchorLink,
  className,
  href,
  ...rest
}) => {
  if (internalLink) {
    return (
      <InternalLinkButtonStyled
        disabled={disabled}
        onClick={onClick}
        to={href}
        buttontype={buttonType || TYPES.PRIMARY}
        buttonsize={buttonSize || SIZES.MEDIUM}
        className={`${className ? className : ''} btn-size-${buttonSize || SIZES.MEDIUM}`}
        {...rest}
      >
        {text}
        {ripple ? <span className="ripple-container"></span> : null}
      </InternalLinkButtonStyled>
    );
  }
  if (anchorLink) {
    return (
      <AnchorLinkButtonStyled
        disabled={disabled}
        // onClick={onClick} onClick doesnt work with AnchorLink :(
        onAnchorLinkClick={ onClick ? () => onClick(null) : null } // no event with AnchorLink either :(
        to={href}
        buttontype={buttonType || TYPES.PRIMARY}
        buttonsize={buttonSize || SIZES.MEDIUM}
        className={`${className ? className : ''} btn-size-${buttonSize || SIZES.MEDIUM}`}
        {...rest}
      >
        {text}
        {ripple ? <span className="ripple-container"></span> : null}
      </AnchorLinkButtonStyled>
    );
  }
  return (
    <ButtonStyled
      onClick={onClick}
      as={element}
      href={href}
      buttontype={buttonType || TYPES.PRIMARY}
      buttonsize={buttonSize || SIZES.MEDIUM}
      className={`${className ? className : ''} btn-size-${buttonSize || SIZES.MEDIUM} ${disabled ? 'disabled' : ''}`}
      {...rest}
    >
      {text}
      {ripple ? <span className="ripple-container"></span> : null}
    </ButtonStyled>
  );
};

BaseButton.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  href: PropTypes.string,
  onClick: PropTypes.func,
  element: PropTypes.string,
  disabled: PropTypes.bool,
  ripple: PropTypes.bool,
  className: PropTypes.string,
  buttonType: PropTypes.string,
  buttonSize: PropTypes.string,
  internalLink: PropTypes.bool,
  anchorLink: PropTypes.bool,
  theme: PropTypes.object,
};

export const Primary = props => (
  <BaseButton { ...props } buttonType={TYPES.PRIMARY} />
);
export const OutlinePrimary = props => (
  <BaseButton { ...props } buttonType={TYPES.OUTLINEPRIMARY} />
);
export const Secondary = props => (
  <BaseButton { ...props } buttonType={TYPES.SECONDARY} />
);
export const OutlineSecondary = props => (
  <BaseButton { ...props } buttonType={TYPES.OUTLINESECONDARY} />
);
export const Tertiary = props => (
  <BaseButton { ...props } buttonType={TYPES.TERTIARY} />
);
export const Light = props => (
  <BaseButton { ...props } buttonType={TYPES.LIGHT} />
);
export const Info = props => (
  <BaseButton { ...props } buttonType={TYPES.INFO} />
);
export const Gray = props => (
  <BaseButton { ...props } buttonType={TYPES.GRAY} />
);
export const Warning = props => (
  <BaseButton { ...props } buttonType={TYPES.WARNING} />
);
export const Danger = props => (
  <BaseButton { ...props } buttonType={TYPES.DANGER} />
);
export const Success = props => (
  <BaseButton { ...props } buttonType={TYPES.SUCCESS} />
);
export const CtaYellow = props => (
  <BaseButton { ...props } buttonType={TYPES.CTA_YELLOW} />
);
export const CtaBlueGreen = props => (
  <BaseButton { ...props } buttonType={TYPES.CTA_BLUE_GREEN} />
);
export const CtaBlue = props => (
  <BaseButton { ...props } buttonType={TYPES.CTA_BLUE} />
);
export const ColorBtn = props => (
  <BaseButton { ...props } buttonType={TYPES.COLOR_BTN} />
);
export const ColorHollowBtn = props => (
  <BaseButton { ...props } buttonType={TYPES.COLOR_HOLLOW_BTN} />
);
